<template>
  <s-row>
    <v-col class="mx-0 px-0">
      <v-container class="mx-0 px-0">
        <v-form ref="formSearch" @submit.prevent="search()" class="formulario">
          <v-row class="mx-0 px-0">
            <v-col class="d-flex" cols="12" md="3" sm="6" xs="12">
              <v-select
                item-text="email"
                v-model="idUserN1Selected"
                item-value="id"
                label="Nivel 1"
                outlined
                :items="userN1"
                :rules="[(v) => !!v || 'Usuario N1 es obligatorio']"
                @change="changeUserN1()"
              />
              <!-- :disabled="btnSearchLoading" -->
            </v-col>
            <v-col class="d-flex" cols="12" md="3" sm="6" xs="12">
              <v-select
                item-text="email"
                v-model="idUserN2Selected"
                item-value="id"
                label="Nivel 2"
                :items="userN2"
                outlined
                :rules="[(v) => !!v || 'Usuario N2 es obligatorio']"
                @change="changeUserN2()"
              />
              <!-- :disabled="btnSearchLoading" -->
            </v-col>
            <v-col class="d-flex" cols="12" md="3" sm="6" xs="12">
              <v-select
                item-text="value"
                v-model="deviceClassSelected"
                item-value="id"
                label="Tipo producto"
                :items="deviceClass"
                outlined
                :rules="[(v) => !!v || 'Tipo de producto es obligatorio']"
                @change="changeDevice()"
              />
              <!-- :disabled="btnSearchLoading" -->
            </v-col>
            <v-col class="d-flex" cols="12" md="3" sm="6" xs="12">
              <v-btn
                height="56px"
                width="100%"
                type="submit"
                color="primary"
                @click="search()"
              >
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-col>
    <gmap :devices="devices" class="mapa"></gmap>
  </s-row>
</template>

<script>
// import gmap from "./Components/Gmap.vue";
import BackendApi from "@/services/backend.service";

export default {
  components: {
    gmap: () => import("./Components/Gmap.vue"),
  },
  data() {
    return {
      userN1: [],
      idUserN1Selected: null,
      userN2: [],
      idUserN2Selected: null,
      deviceClassSelected: null,
      deviceClass: [
        {
          id: 100,
          value: "Todos",
        },
      ],
      devices: [],
    };
  },
  created() {
    this.getUserN1();
    this.typeProduct();
  },
  computed: {
    isAdmin() {
      return (
        this.$cookies.get("isSuperAdmin") === "true" ||
        this.$cookies.get("isAdmin") === "true"
      );
    },
    isN1() {
      return this.$cookies.get("user").profileId === 3;
    },
    isN2() {
      return this.$cookies.get("user").profileId === 4;
    },
  },
  methods: {
    typeProduct() {
      if (this.$cookies.get("agua") === "true") {
        this.deviceClass.push({
          id: 1,
          value: "Agua",
        });
      }

      if (this.$cookies.get("energia") === "true") {
        this.deviceClass.push({
          id: 2,
          value: "Energía",
        });
      }
      if (this.$cookies.get("gas") === "true") {
        this.deviceClass.push({
          id: 3,
          value: "gas",
        });
      }
    },
    getUserN1() {
      if (this.isAdmin) {
        BackendApi.get("/user/usersN1ForReport")
          .then((response) => {
            if (response.data.success) {
              this.userN1 = response.data.data;
            }
          })
          .catch(() => {});
      } else if (this.isN1) {
        const user = [
          {
            id: this.$cookies.get("user").id,
            email: this.$cookies.get("user").email,
          },
        ];
        this.userN1 = user;
      } else if (this.isN2) {
        BackendApi.get("/user/father")
          .then((response) => {
            if (response.data.success) {
              this.userN1 = response.data.data;
            }
          })
          .catch(() => {});
      }
    },
    getUserN2() {
      if (!this.isN2) {
        BackendApi.get("/user/usersN2ForReport/" + this.idUserN1Selected)
          .then((response) => {
            if (response.data.success) {
              this.userN2 = response.data.data;
            }
          })
          .catch(() => {});
      } else {
        const user = [
          {
            id: this.$cookies.get("user").id,
            email: this.$cookies.get("user").email,
          },
        ];
        this.userN2 = user;
      }
    },
    // deviceForUserN2() {
    //   this.devices = [];
    //
    //   BackendApi.get("/device/deviceRelatedToUserN2/" + this.idUserN2Selected)
    //     .then((response) => {
    //       if (response.data.success) {
    //         this.devices = response.data.data.filter(
    //           (device) => device.device_product.deviceClassId === 1
    //         );
    //       }
    //     })
    //     .catch(() => {
    //
    //     });
    // },
    changeUserN1() {
      this.getUserN2();

      this.idUserN2Selected = null;
    },
    changeUserN2() {
      // this.deviceForUserN2();
    },
    changeDevice() {
      // this.usersN2ForReport();
    },
    search() {
      if (this.$refs.formSearch.validate()) {
        this.devices = [];

        BackendApi.get("/device/deviceRelatedToUserN2/" + this.idUserN2Selected)
          .then((response) => {
            if (response.data.success) {
              let aux_devices = [];
              if (this.deviceClassSelected !== 100) {
                aux_devices = response.data.data.filter(
                  (device) =>
                    device.device_product.deviceClassId ===
                    this.deviceClassSelected
                );
              } else {
                aux_devices = response.data.data;
              }
              aux_devices.forEach((element) => {
                var icon = "";
                if (element.device_product.deviceClassId === 1) {
                  icon =
                    "http://maps.google.com/mapfiles/ms/icons/orange-dot.png";
                } else if (element.device_product.deviceClassId === 2) {
                  icon =
                    "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
                } else if (element.device_product.deviceClassId === 3) {
                  icon =
                    "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
                } else if (element.device_product.deviceClassId === 4) {
                  icon = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
                } else {
                  icon =
                    "https://maps.google.com/mapfiles/ms/icons/purple-dot.png";
                }

                this.devices.push({
                  detalle: {
                    icon: icon,
                    ...element,
                  },
                  position: {
                    lat: parseFloat(element.latitud),
                    lng: parseFloat(element.longitud),
                  },
                });
              });
            }
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style scoped>
.mapa {
  margin-top: -35px;
}
.formulario {
  margin-top: -30px;
}
</style>
